import React from "react"
import BgImage from "../images/bg/1.jpg"
import { Link } from "gatsby"
import TypeWriter from "./TypeWriter"
export default function Hero({ data }) {
  return (
    <section
      className="py-36 lg:py-64 w-full table relative"
      style={{
        background: `url(${BgImage}) center`,
      }}
      id="home"
    >
      <div className="absolute inset-0 bg-black opacity-80" />
      <div className="container relative">
        <div className="grid grid-cols-1 mt-12">
          <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
            Providing Financial Services <br />
            For Your
            <span className={"pl-2 inline-block underline"}>
              <TypeWriter
                startDelay={350}
                cursorColor="#eee"
                multiText={[
                  "Business",
                  "Startups",
                  "Digital Agency",
                  "Marketing",
                ]}
                multiTextDelay={1300}
                typeSpeed={200}
              />
            </span>
          </h4>

          <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
            {data.description}
          </p>

          <div className="relative mt-10">
            <Link
              to="/get-started"
              className="btn btn-primary bg-orange-500 rounded-md"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
  // return (
  //     <div className="pt-24">
  //       <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
  //         <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
  //           <h1 className="my-4 text-5xl font-bold leading-tight">
  //             {data.title}
  //           </h1>
  //           <p className="leading-normal text-2xl mb-8 prose">
  //             {data.description}
  //           </p>
  //         </div>
  //         <div className="w-full md:w-3/5 py-6 text-center">
  //           <img className="w-full md:w-4/5 z-50 mx-auto"
  //                        src={data.picture.localFile.url}
  //                        alt={data.picture.caption}
  //           />
  //         </div>
  //       </div>
  //     </div>
  // )
}
