import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.css"

export default function Testimonies2() {
  const data = useStaticQuery(query)
  const review = data.allStrapiTestimonial.nodes
  return (
    <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h6 className="text-primary-600 text-base font-medium uppercase mb-2">
          Testimonial
        </h6>
        <h3 className="mb-4 md:text-2xl text-xl font-medium text-black">
          Client's Review
        </h3>
      </div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby={"svg"}
        >
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>
        <Carousel
          autoPlay
          infiniteLoop
          swipeable
          interval={6000}
          transitionTime={1000}
        >
          {review?.map(r => (
            <TestimonyItem review={r} key={r.text} />
          ))}
        </Carousel>
      </div>
    </section>
  )
}

const TestimonyItem = ({ review }) => (
  <div className={"h-full"}>
    {review.logo && review.logo.localFile && (
      <img
        className="mx-auto h-8"
        src={review.logo.localFile.url}
        alt={review.logo.caption}
      />
    )}

    <blockquote className="mt-10 mb-4">
      <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
        <p>&ldquo;{review.text}&rdquo;</p>
      </div>
      <footer className="mt-8">
        <div className="md:flex md:items-center md:justify-center">
          <div className="md:flex-shrink-0">
            {review.picture && review?.picture.localFile && (
              <img
                className="mx-auto !h-10 !w-10 rounded-full"
                src={review.picture.localFile.url}
                alt={review.picture.caption}
              />
            )}
          </div>
          <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
            <div className="text-base font-medium text-gray-900">
              {review.authorName}
            </div>

            <svg
              className="hidden md:block mx-1 h-5 w-5 text-orange-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M11 0h3L9 20H6l5-20z" />
            </svg>

            <div className="text-base font-medium text-gray-500">
              {review.authorTitle} {review.Company ? `, ${review.Company}` : ""}
            </div>
          </div>
        </div>
      </footer>
    </blockquote>
  </div>
)

const query = graphql`
  query SingleTestimonyQuery {
    allStrapiTestimonial(sort: { order: DESC, fields: updatedAt }) {
      nodes {
        text
        authorTitle
        authorName
        Company
        logo {
          localFile {
            url
          }
          caption
        }
        picture {
          caption
          localFile {
            url
          }
        }
      }
    }
  }
`
