import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import UnSplashCompany from "../images/kelly-sikkema-xoU52jUVUXA-unsplash.jpg"

export default function CompanyStory() {
  const aboutUs = useStaticQuery(graphql`
    query CompanyStoryQuery {
      strapiAboutUs {
        title
        subTitle
        description {
          data {
            description
          }
        }
      }
    }
  `)
  const {
    title,
    subTitle,
    description: {
      data: { description },
    },
  } = aboutUs.strapiAboutUs
  return (
    <section
      className="relative md:py-24 py-16 bg-zinc-50 dark:bg-gray-800"
      id="about"
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
          <div className="lg:col-span-5">
            <div className="relative">
              <img
                src={UnSplashCompany}
                className="rounded-lg shadow-lg relative"
                alt=""
              />
            </div>
          </div>
          <div className="lg:col-span-7">
            <div className="lg:ml-7">
              <h6 className="text-primary-600 text-base font-medium uppercase mb-2">
                {subTitle}
              </h6>
              <h3 className="mb-4 md:text-2xl text-xl font-medium text-black">
                {title}
              </h3>

              <p className="text-gray-600 dark:text-slate-300 max-w-2xl mx-auto">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
