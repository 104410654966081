import React from "react"
import { classNames } from "../utils/classNameFilter"
import { graphql, useStaticQuery } from "gatsby"
import Markdown from "react-markdown"

export default function Process() {
  const { strapiProcess: process } = useStaticQuery(graphql`
    query ProcessQuery {
      strapiProcess {
        id
        missionTitle
        missionStatement {
          data {
            missionStatement
          }
        }
        missionSteps {
          title
          listItems {
            text
          }
          image {
            caption
            localFile {
              url
            }
          }
        }
      }
    }
  `)
  const steps = process.missionSteps
  return (
    <div className="container md:mt-24 mt-16">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h6 className="text-primary-600 text-base font-medium uppercase mb-2">
          Work Process
        </h6>
        <h3 className="mb-4 md:text-2xl text-xl font-medium text-black">
          {process.missionTitle}
        </h3>
        <Markdown
          source={process.missionStatement.data.missionStatement}
          className="text-gray-400 max-w-xl mx-auto"
        />
      </div>

      <div className="grid grid-cols-1 mt-8">
        <div className="timeline relative">
          {steps.map((step, index) => {
            const odd = index % 2 === 0
            if (odd)
              return (
                <div className="timeline-item" key={step.title}>
                  <div className="grid sm:grid-cols-2">
                    <div>
                      <div className="duration date-label-left float-right md:mr-7 relative">
                        <img
                          src={step.image.localFile.url}
                          className="h-64 w-64"
                          alt={step.image.caption}
                        />
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0">
                      <div className="event event-description-right float-left text-left md:ml-7">
                        <h5 className="text-lg text-black mb-1 font-medium">
                          {step.title}
                        </h5>
                        <div className="timeline-subtitle mt-3 mb-0 text-gray-400">
                          <ul>
                            {step.listItems.map((listItem, index2) => (
                              <li key={`process-li-${index2}`}>
                                {listItem.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            else
              return (
                <div className="timeline-item mt-5 pt-4" key={step.title}>
                  <div className="grid sm:grid-cols-2">
                    <div className="md:order-1 order-2">
                      <div className="event event-description-left float-left text-right md:mr-7">
                        <h5 className="text-lg mb-1 text-black font-medium">
                          {step.title}
                        </h5>
                        <div className="timeline-subtitle mt-3 mb-0 text-gray-400">
                          <ul>
                            {step.listItems.map((listItem, index2) => (
                              <li key={`process-li-${index2}`}>
                                {listItem.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="md:order-2 order-1">
                      <div className="duration duration-right md:ml-7 relative">
                        <img
                          src={step.image.localFile.url}
                          className="h-64 w-64"
                          alt={step.image.caption}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
          })}
        </div>
      </div>
    </div>
  )
  // return (
  //   <div className={'bg-white pt-10 mt-0'}>
  //     <div className="container max-w-5xl mx-auto mx-8">
  //       <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
  //         {process.missionTitle}
  //       </h1>
  //       <div className="w-full mb-4">
  //         <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"/>
  //       </div>
  //       <p className="w-full m-2 leading-tight text-center text-gray-800">
  //         {process.missionStatement.data.missionStatement}
  //       </p>
  //       { steps.map((step, index) => {
  //         const odd = index % 2 === 0;
  //         return (
  //           <div className={classNames('flex flex-wrap',
  //             odd && 'flex-col-reverse sm:flex-row')} key={`process-section-${index}`}>
  //             {odd &&
  //               <>
  //                 <div className="w-full sm:w-1/2 p-6 mt-6">
  //                   <img
  //                     className={"max-h-80 float-none md:float-right"}
  //                     alt={step.image.caption}
  //                     src={step.image.localFile.url}/>
  //                 </div>
  //                 <div className="w-full sm:w-1/2 p-6 mt-6 flex">
  //                   <div className="flex flex-col justify-center">
  //                     <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
  //                       {step.title}
  //                     </h3>
  //                     <div className="text-gray-600 mb-8">
  //                       <ul>
  //                         {step.listItems.map((listItem, index2) => (
  //                           <li key={`process-li-${index2}`}>{listItem.text}</li>
  //                         ))}
  //                       </ul>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </>
  //             }
  //
  //             { !odd &&
  //               <>
  //                 <div className="w-5/6 sm:w-1/2 p-6 flex">
  //                   <div className="flex flex-col justify-center">
  //                     <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
  //                       {step.title}
  //                     </h3>
  //                     <div className="text-gray-600 mb-8">
  //                       <ul>
  //                         {step.listItems.map((listItem, index3) => (
  //                           <li key={`process-li-${index3}`}>{listItem.text}</li>
  //                         ))}
  //                       </ul>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="w-full sm:w-1/2 p-6">
  //                   <img
  //                     className={"max-h-80 float-none md:float-left"}
  //                     alt={step.image.caption}
  //                     src={step.image.localFile.url}/>
  //                 </div>
  //               </>
  //             }
  //           </div>
  //         )
  //       })}
  //     </div>
  //   </div>
  // )
}
