import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Markdown from "react-markdown"

export default function AboutUs() {
  const aboutUs = useStaticQuery(graphql`
    query AboutUsQuery {
      strapiAboutUs {
        employees {
          name
          position
          image {
            localFile {
              url
            }
            caption
          }
          shortDescription
          description {
            data {
              description
            }
          }
        }
        title
        shortDescription
        description {
          data {
            description
          }
        }
      }
    }
  `)
  const {
    title,
    shortDescription,
    description: {
      data: { description },
    },
    employees,
  } = aboutUs.strapiAboutUs
  return (
    <section
      className="relative md:py-24 py-16 bg-zinc-50 dark:bg-gray-800"
      id="team"
    >
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h6 className="text-primary-600 text-base font-medium uppercase mb-2">
            Our Team
          </h6>
          <h3 className="mb-4 md:text-2xl text-xl font-medium text-black">
            {title}
          </h3>
          <Markdown
            source={shortDescription}
            className="text-gray-400 dark:text-slate-300 max-w-xl mx-auto"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          {employees.map(person => (
            <div
              className="relative rounded-md shadow-lg overflow-hidden group"
              key={person.name}
            >
              <img
                src={person.image.localFile.url}
                className="group-hover:origin-center group-hover:scale-125 transition duration-500"
                alt={person.caption}
              />
              <div className="absolute inset-0 group-hover:bg-black opacity-30 z-0" />
              <div
                className="content absolute left-4 bottom-4 right-4 bg-white dark:bg-black hidden
                group-hover:block p-4 rounded-md text-center"
              >
                <p className="h5 text-md group-hover:text-primary-600 hover:text-primary-600 font-medium">
                  {person.name}
                </p>
                <h6 className="text-gray-400 dark:text-slate-300 mb-0 font-light">
                  {person.position}
                </h6>
                <Markdown
                  source={person.description.data.description}
                  className="text-gray-400 dark:text-slate-300 max-w-xl mx-auto"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
