import * as React from "react"
import { Link } from "gatsby"

export default function CallToAction() {
  return (
    <section className="py-24 w-full table relative">
      <div className="absolute inset-0 bg-black opacity-80" />
      <div className="container relative">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">
            Ready to start your financial freedom?
          </h3>

          <p className="text-white opacity-50 max-w-xl mx-auto">
            Click the Get Started button below so we can get connected!
          </p>

          <div className="relative mt-10">
            <Link to="/get-started" className="btn btn-primary rounded-md">
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
