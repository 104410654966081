import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import CompanyStory from "../components/CompanyStory"
import Results from "../components/Results"
import Process from "../components/Process"
import CallToAction from "../components/CallToAction"
import Testimonies2 from "../components/Testimonies2"
import AboutUs from "../components/AboutUs"
import ContactUsSection from "../components/sections/ContactUsSection"

export default function Index({ data }) {
  const {
    strapiGlobal,
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
      },
    },
  } = data

  return (
    <>
      <SEO seo={{ title: "Haven Financial" }} global={strapiGlobal} />
      <Layout
        landing={true}
        pageContext={{
          locale: defaultLocale,
          locales,
          defaultLocale,
          slug: "/",
        }}
        global={strapiGlobal}
      >
        <Hero data={{ ...data.strapiHero }} />
        <CompanyStory />
        <Results />
        <Process />
        <CallToAction />
        <Testimonies2 />
        <AboutUs />
        <ContactUsSection
          email={strapiGlobal.emailContact}
          phone={strapiGlobal.phoneContact}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query LandingQuery {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
    strapiGlobal {
      metaTitleSuffix
      instagram
      facebook
      twitter
      emailContact
      phoneContact
      metadata {
        metaTitle
        metaDescription
      }
      locale
      favicon {
        localFile {
          url
        }
      }
      logo {
        localFile {
          url
        }
        caption
      }
    }
    strapiHero {
      title
      locale
      description
      picture {
        localFile {
          url
        }
      }
    }
  }
`
