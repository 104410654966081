import React, { useEffect, useRef, useState } from "react"
import "../styles/typewriter.css"

export default function TypeWriter({
  cursorColor,
  textStyle,
  startDelay,
  multiText,
  text = "",
  multiTextDelay = 2000,
  typeSpeed = 2000,
  eraseSpeedDelay = 50,
  blink = true,
  hideCursor = false,
  hideCursorAfterText = false,
  multiTextLoop = true,
}) {
  const myRef = useRef()
  const [blinkState, setBlinkState] = useState(blink)
  const [hideCursorState, setHideCursorState] = useState(hideCursor)
  const [displayText, setDisplayText] = useState("")

  useEffect(() => {
    const delay = async ms => {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, ms)
      })
    }
    const runAnimation = async (str, erase) => {
      const textArr = typeof str == "string" && str.trim().split("")
      let text = ""
      if (textArr) {
        setBlinkState(false)
        for (let char = 0; char < textArr.length; char++) {
          await delay(typeSpeed)
          text += textArr[char]
          setDisplayText(text)
        }
        setBlinkState(true)
      }
      if (multiText) {
        await delay(multiTextDelay)
      }
      if (erase) {
        await eraseText(text)
      }
    }
    const eraseText = async str => {
      const textArr = typeof str == "string" && str.trim().split("")
      setBlinkState(false)
      for (let char = 0; char < textArr.length; char++) {
        await delay(eraseSpeedDelay)
        str = str.slice(0, -1)
        setDisplayText(str)
      }
      setBlinkState(true)
    }
    const multiTextDisplay = async arr => {
      for (let e = 0; e < arr.length; e++) {
        await runAnimation(arr[e], e < arr.length - 1)
      }
      if (multiTextLoop) {
        await eraseText(arr[arr.length - 1])
        await multiTextDisplay(arr)
      }
    }
    const start = async () => {
      if (startDelay) await delay(startDelay)
      if (multiText) {
        await multiTextDisplay(multiText)
      } else {
        await runAnimation(text)
      }
      if (hideCursorAfterText) {
        setHideCursorState(true)
      }
    }
    start()
  }, [])

  return (
    <div ref={myRef} className={"react-typewriter-text-wrap"}>
      <h1 style={{ ...textStyle }} className="react-typewriter-text">
        {displayText}
        <div
          className={`react-typewriter-pointer ${
            blinkState && "add-cursor-animate"
          } ${hideCursorState ? "hide-typing-cursor" : ""}`}
          style={{ backgroundColor: `${cursorColor}` }}
        />
      </h1>
    </div>
  )
}
