import React from "react"
import GetStartedForm from "../GetStartedForm"
import { MailIcon, PhoneIcon } from "@heroicons/react/outline"

export default function ContactUsSection({ email, phone }) {
  return (
    <section
      className="relative md:py-24 py-16 bg-gray-100 dark:bg-gray-800"
      id="contact"
    >
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h6 className="text-primary-600 text-base font-medium uppercase mb-2">
            Contact us
          </h6>
          <h3 className="mb-4 md:text-2xl text-xl font-medium text-black">
            Get In Touch !
          </h3>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
          <div className="lg:col-span-8">
            <div className="p-6 rounded-md shadow bg-white dark:bg-black">
              <GetStartedForm />
            </div>
          </div>

          <div className="lg:col-span-4">
            <div className="lg:ml-8">

              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <MailIcon
                    className="flex-shrink-0 w-6 h-6 text-black"
                    aria-hidden="true"
                  />
                </div>

                <div className="flex-1 ml-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">
                    Email
                  </h5>
                  <a href={`mailto:${email}`} className="text-gray-400">
                    {email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
