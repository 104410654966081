import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Markdown from "react-markdown"

export default function Results() {
  const result = useStaticQuery(query)
  const data = result.strapiResult
  const count = data.resultItems.length
  return (
    <section className="relative md:py-24 py-16" id="features">
      <div className="container lg mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
          <div>
            <h6 className="text-primary-600 text-base font-medium uppercase mb-2">
              What We Do ?
            </h6>
            <h3 className="mb-4 md:text-2xl text-xl font-semibold md:mb-0 text-black">
              {data.title}
            </h3>
          </div>

          <div>
            <Markdown
              source={data.description.data.description}
              className="text-gray-400 dark:text-slate-300 max-w-xl"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
          {data.resultItems.map(r => (
            <div
              key={r.title}
              className="features p-6 shadow-xl hover:shadow-2xl hover:shadow-slate-100
             transition duration-500 rounded-3xl mt-8"
            >
              <div
                className="w-20 h-20 bg-primary-600/5 text-primary-600 rounded-xl
                text-3xl flex align-middle justify-center items-center shadow-sm"
              >
                <img src={r.image.localFile.url} alt={r.image.caption} />
              </div>
              <div className="content mt-7">
                <p className="text-lg text-primary-600 font-medium">
                  {r.title}
                </p>
                <p className="text-gray-500 mt-3">
                  {r.description.data.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
  // return (
  //   <div className="bg-gray-100 text-gray-700">
  //     <div className="max-w-2xl mx-auto py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
  //       <div className="max-w-3xl">
  //         <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
  //           {data.title}
  //         </h2>
  //         <Markdown source={data.description.data.description} className="my-4 text-gray-500"/>
  //       </div>
  //       <div className={`grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-${count} lg:gap-x-8`}>
  //         {data.resultItems.map((incentive) => (
  //           <div key={incentive.title}>
  //             <img src={incentive.image.localFile.url} alt={incentive.image.caption} className="h-24 w-auto" />
  //             <h3 className="mt-6 text-sm font-medium text-gray-900">{incentive.title}</h3>
  //             <p className="mt-2 text-sm text-gray-500">{incentive.description.data.description}</p>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </div>
  // )
}

const query = graphql`
  query ResultsQuery {
    strapiResult {
      title
      description {
        data {
          description
        }
      }
      resultItems {
        title
        description {
          data {
            description
          }
        }
        image {
          caption
          localFile {
            url
          }
        }
      }
    }
  }
`
